import React, { Component } from "react";
import axios from "axios";
import { Button, Form, Spinner } from "react-bootstrap";
import { isMobile, browserName, engineVersion, browserVersion, osName, osVersion } from 'react-device-detect';
import { Link, withRouter } from "react-router-dom";
import Lottie from "react-lottie";
import toast from "react-hot-toast";
import loading from "../../assets/animation/main_loader.json";
import "./index.css";
import firebase from "../../firebase";
import UrlConfig from '../../utils/ApiConfig';
import { MainContext } from "../../context";
import { Translate } from "react-auto-translate";
import { requestPermission } from "../../utils";

const loadingLottie = {
    loop: true,
    autoplay: true,
    animationData: loading,
};

class NewAccount extends Component {
  static contextType = MainContext;
  constructor(props) {
    super(props);
    this.state = {
        nick: this.props.walletid.slice(this.props.walletid.length -6),
        message: "",
        loading: false,
        checked: false,
        isMobile: isMobile,
        WalletId: this.props.walletid,
        terms: "",
        device: `M${+isMobile}_B_${browserName}_V_${engineVersion}${browserVersion}_OS_${osName}_${osVersion}`.replace(/\./g, '_').replace(/\s+/g, ''),
        ip: this.props.ip,
        countryCode: this.props.countryCode,
        fmcToken: this.props.fmcToken || localStorage.getItem("fmcToken"),
        walletName: this.props.walletName,
    };
    this.handleRegisterClick = this.handleRegisterClick.bind(this);
  }

  async componentDidMount() {
    try {
      // Geolocation call    
      const res = await axios.get(UrlConfig.geoLocationUrl);
      const values = res.data.split("\n");
      let obj = {};
      values.forEach((value) => {
        obj[value.split("=")[0]] = value.split("=")[1];
      });
      let data = { IPv4: obj.ip, country_code: obj.loc };
      this.setState({
        ip: data.IPv4,
        countryCode: obj.loc
      });
    } catch (error) {
      console.error("Error fetching geolocation data:", error);
      this.setState({
        ip: "",
        countryCode: ""
      });
    }

    // Capture UTM Parameters
    this.trackAccountCreation();
  }

  trackAccountCreation() {
    const utmSource = sessionStorage.getItem('utm_source');
    const utmCampaign = sessionStorage.getItem('utm_campaign');
    const utmMedium = sessionStorage.getItem('utm_medium');
    if (utmSource && utmCampaign && utmMedium) {
        // Send Ads event to Google Analytics
        window.gtag('event', 'AdsSource', {
            'Source': utmSource,
            'Campaign': utmCampaign,
            'Medium': utmMedium
        });
        // Send ConnectWalletSource event to Google Analytics
        window.gtag('event', 'ConnectWalletSource', {
          'event_category': 'Account',
          'event_label': `Source: ${utmSource}, Campaign: ${utmCampaign}, Medium: ${utmMedium}`,
          'value': 1,
          'Source': utmSource,
          'Campaign': utmCampaign,
          'Medium': utmMedium,
      });
    }
  }

  handleChange = (e) => {
    const { checked } = e.target;
    this.setState({
      checked: checked,
      message: "",
    });
  };    

  termsnValidate = () => {
    let termsChecked = this.state.checked;
    let termsError = "You must agree to our terms and conditions";
    if (termsChecked === false) {
      this.setState({ 
        message: termsError 
    });
      return false;
    } else if (termsChecked === true) {
        this.setState({ 
            message: "",
        });
        return true;
    }
  };

  handleRegisterClick = async (event) => {
    try {
    event.preventDefault();
    const termsValid = this.termsnValidate();
    if (termsValid) { 
        this.setState({ loading: true });
        toast.custom(<Lottie options={loadingLottie} width={100} height={100} />, {
          duration: 9000,
            style: {
              borderRadius: "15px",
            },
        });
        const nick = this.props.walletid.slice(this.props.walletid.length -6);
        localStorage.removeItem("idtoken");
        const res = await axios.get(UrlConfig.walletConnectAPI, {
            params: {
              nick,
              countryCode: this.state.countryCode || this.props.countryCode,
              widProvider: this.props.walletName || this.state.walletName,
              isMobile: isMobile.toString(),
            },
            headers: {
              device: this.state.device,
              ip: this.state.ip,
              walletid: this.props.walletid || this.state.walletid,
              fmcToken: this.state.fmcToken,
              idtoken: undefined,
            },
            withCredentials: true,
            credentials: "include",
        });
    
      // Handle the successful response here
          this.setState({ loading: false });
          if (res.data.error === "logged in with same device" && res.data.goProfile) {
            //user goes to profile page;
                this.context.setBaseStates({
                    ...res.data.getDoc.doc,
                    uid: "",
                    doc: res.data.getDoc.doc,
                    userDoc: res.data.getDoc.userDoc,
                    inGame: res.data.getDoc.inGame,
                    inQue: res.data.getDoc.inQue,
                    currency: res.data.getDoc.currency,
                    datetimeNow: res.data.getDoc.datetimeNow
                });
                if(window.location.pathname === "/"){
                  window.location.href="/profile"
                }
                 //checkes for the push Notification popup
                 if(res.data.TriggerFcmPopup){
                  await requestPermission(res.data.OverrideFcmDevice, this.props.walletid)
                }
                } else if (res.data.error !== "no error") {
                // connectWallet.LOGIN OR connectWallet.REGISTER FAILED;
                // user stays in login page and must retry later;
                toast.error(<Translate>{res.data.error}</Translate>, {
                  duration: 4000,
                  style: {
                      maxWidth: 800,
                      fontSize: "14px",
                      borderRadius: "15px",
                  },
                });
                } else {
                // SUCCESS, user goes to profile page;
                this.context.setBaseStates({
                    ...res.data.getDoc.doc,
                    uid: "",
                    doc: res.data.getDoc.doc,
                    userDoc: res.data.getDoc.userDoc,
                    inGame: res.data.getDoc.inGame,
                    inQue: res.data.getDoc.inQue,
                    currency: res.data.getDoc.currency,
                    datetimeNow: res.data.getDoc.datetimeNow,
                    isNewUser: true
                });
                const currentUser = await firebase.auth().signInWithCustomToken(res.data.idtoken || localStorage.getItem("idtoken"))
                const idToken = await currentUser.user.getIdToken(true);
                this.context.setToken(idToken);
                this.context.setFirebaseUser(currentUser.user);
                localStorage.setItem("idtoken", idToken);
                toast.success(
                  <span>
                    <Translate>Welcome to </Translate>
                    WEJE!
                  </span>,
                  {
                    duration: 4000,
                    style: {
                      maxWidth: 800,
                      fontSize: "14px",
                      borderRadius: "15px",
                    },
                  }
                );
                localStorage.setItem("deviceId", this.state.device);
                localStorage.setItem("fmcToken", this.state.fmcToken);
                localStorage.setItem("provider", this.props.walletName);
                if(res.data.msg === "refresh idtoken, set local storage, and call CookieSetter" || res.data.msg === "registration success" || res.data.msg === "call the cookie-setter-api"){
                  await axios.get(`${UrlConfig.cookieSetterAPI}`, {
                    headers: {
                        idtoken: idToken,
                        wid: this.props.walletid,
                    },
                    params: {
                        isLogout: "no"
                    },
                    withCredentials: true,
                    credentials: "include"
                   })
                    //checkes for the push Notification popup
                 if(res.data.TriggerFcmPopup){
                  requestPermission(res.data.OverrideFcmDevice, this.props.walletid)
                }
              }
               if(window.location.pathname === "/"){
                  window.location.href="/profile?modalType=welcome"
                }
            }
        } 
      } catch (error) {
        console.log("Error in register click function", error)
      }
  }

  render() {

        return (
          <>
            <div>
                <Form>  
                <div className="step-3">
                    <div className="agreebox">
                    <input
                        type="checkbox"
                        className="checkbox16" 
                        onChange={(e) => this.handleChange(e)}
                        defaultChecked={this.state.checked} />
                    <small>
                        <Translate>&nbsp; I’m 21+ and agree to{" "}</Translate>
                        <Link
                        className="signup-link"
                        target="_blank"
                        to={"/terms-of-use"} >
                            <Translate>Terms </Translate>
                        </Link>
                          <Translate>and</Translate>
                        <Link
                        className="signup-link"
                        target="_blank"
                        to={"/privacy-policy"} >
                            <Translate> Privacy policy</Translate>
                        </Link>
                    </small>
                    </div>
                    <div className="text-center">
                    <Button
                        className="google-login-btn create-account"
                        type="submit" 
                        onClick={this.handleRegisterClick}
                        disabled={this.state.loading} >
                        {this.state.loading ? <Spinner animation="border" /> : <Translate>Create Account</Translate>}
                    </Button>
                    </div>
                    <p style={{ color: "#8461dc" }}><Translate>{this.state.message}</Translate></p>
                </div>
                </Form>
            </div>
          </>
        );
    }
}
export default withRouter(NewAccount);
