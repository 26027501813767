import React, { useContext, useEffect, useState, useMemo } from 'react';
import { Form, Modal, Button, Spinner, OverlayTrigger, Tooltip } from "react-bootstrap";
import Layout from "../layout/layout";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import toast from "react-hot-toast";
import CryptoNOT from "../cryptoNOT/index";
import { Link } from "react-router-dom";
import { isMobile, browserName, engineVersion, browserVersion, osName, osVersion } from 'react-device-detect';
import axios from "axios";
import { ethers } from "ethers";
import { useWeb3React } from "@web3-react/core";
import confirm from "../../assets/animation/confirm.json";
import blockc from "../../assets/animation/block.json";
import Lottie from "react-lottie";
import { Translate } from "react-auto-translate";
import info from "../../assets/images/icons/info_30.png";
import PolyLogo from "../../assets/animation/Poker.png";
import walletIcon from "../../assets/images/popups/wallet-logo.png";
import { MainContext } from '../../context';
import LudoAbi from "../../ABI/LudoAbi.json";
import UrlConfig from "../../utils/ApiConfig";
import MinimumBet from "./minbet";
import { v4 } from "uuid";
import { cookieSetter } from '../../dbFetchFunctions';

let device = `M${+isMobile}_B_${browserName}_V_${engineVersion}${browserVersion}_OS_${osName}_${osVersion}`.replace(/\./g, '_').replace(/\s+/g, '');
let activeTx;

const confirmLottie = {
  loop: true,
  autoplay: true,
  animationData: confirm,
};

const blockLottie = {
  loop: true,
  autoplay: true,
  animationData: blockc,
};

const timeoutArray = [
    { value: "15", label: "15 second" },
    { value: "20", label: "20 second" },
];


function LudoCreateTable() {
  const [contract, setContract] = useState();
  const { firebaseUser: user, friendList } = useContext(MainContext);
    const [playerSelected, setplayerSelected] = useState([]);
    const [walletConnected, setwalletConnected] = useState(localStorage.getItem("isWalletConnected"));
    const [balance, setBalance] = useState('');
    const [balanceInUSD, setBalanceInUSD] = useState(0.0);
    const [wallet_min, setwallet_min] = useState(1);
    const [timeoutSelected, settimeoutSelected] = useState({ value: 15, label: "15 second" });
    const [gameTime, setGameTime] = useState({value: 5, label: "5 minutes"})
    const [pickerSchedule, setpickerSchedule] = useState(false);
    const [selectDate, setselectDate] = useState('no');
    const [selectedTime, setselectedTime] = useState(null);
    const [deviceId] = useState(localStorage.getItem("deviceId"));
    const [gameType, setgameType] = useState({label:'MTimer', value: "LudoMoveableTimer_Tables", desc:"All players can move, and the highest scorer when time expires wins" });
    const gametypes = [
      { value: "LudoClassic_Tables", label: "Classic", desc: "The first-place winner gets 70% of the prize, the second gets 20%, and the third gets 10%" },
      { value: "LudoTimer_Tables", label: "Timer", desc: "The game ends after a set time, with the highest-scoring player winning" },
      { value: "LudoMoveableTimer_Tables", label: "Moveable Timer", desc: "All players can move, and the highest scorer when time expires wins" },
    ];
    const [public_game, setpublic_game] = useState(true);
    const [allow_round, setallow_round] = useState(true);
    const [media, setmedia] = useState("no-media");
    const [video_hide, setvideo_hide] = useState(false);
    const [audio_hide, setaudio_hide] = useState(false);
    const [loading, setloading] = useState(false);
    const [message, setmessage] = useState('');
    const [confirmationText, setconfirmationText] = useState('');
    const { account, provider } = useWeb3React();
    const [buyIn, setbuyIn] = useState(10);
    const [confirmationShow, setconfirmationShow] = useState(false);

    const playersArray = useMemo(() => {
      return friendList.map(el => ({
        value: el.id,
        label: el.value
      }))
    }, [friendList])

    const createGame = async (gameId, link) => {
      let tx;
      try {
        if (contract) {
          const depositAmountInMatic = buyIn;
          const valueToSend = ethers.utils.parseUnits(depositAmountInMatic.toString(), 18);
          const minBet = ethers.utils.parseUnits((buyIn).toString(), 18);
         
          // const { data: { result: { FastGasPrice } = {} } = {} } = await axios.get(`${UrlConfig.gasTrackerUrl}${process.env.REACT_APP_API_TOKEN}`);
           // estimeate gasPrice using estimateGas functions
           console.log("fast gas price", await provider.getGasPrice());
           let args = [
            [gameId, gameType.value, minBet, allow_round, media, public_game, parseInt(timeoutSelected.value), gameTime.value], 
           [user.uid, valueToSend, account, user.photoURL, user.displayName],
           playerSelected.map(el => el.value)]
          
        const estimatedGasLimit = await contract.estimateGas.createGame(...args, {
           value: valueToSend,
          gasPrice: await provider.getGasPrice() || ethers.utils.parseUnits('35', 'gwei'),
          nonce: await provider.getTransactionCount(account),
        });
        
        console.log("limit", ethers.BigNumber.from(Math.floor(Number(estimatedGasLimit) * 1.2)), Math.floor(Number(estimatedGasLimit) * 1.2));
        activeTx = true;
        tx = await contract.createGame(...args,
           {
            value: valueToSend,
            gasPrice: await provider.getGasPrice(),
            nonce: await provider.getTransactionCount(account),
            gasLimit: ethers.BigNumber.from(Math.floor(Number(estimatedGasLimit) * 1.2)),
          });
          activeTx = null;
          setconfirmationText('Waiting For Block Confirmation');
          const receipt = await tx.wait();
          console.log("rrrr", receipt);
          
         return receipt;
        }
      } catch (e) {
      if (e.message.includes("transaction was replaced")) {
        activeTx = null;
        window.location.href = link;
        return;
      }
      console.log("eee", e.message);
        if (e.message.includes("execution reverted")) {
          const revertReason = await contract.provider.getTransactionReceipt(tx.hash)
            .then((receipt) => receipt ? receipt.reason : "No revert reason");
          console.error("Revert Reason:", revertReason);
          toast.error(
            <span>
              <Translate>Reason: </Translate> <Translate>{revertReason}</Translate>
            </span>,
            {
              duration: 4000,
              id: "toast-error",
              style: {
                maxWidth: 800,
                fontSize: "14px",
                borderRadius: "15px",
              },
            }
          );
          activeTx = null;
          setconfirmationShow(true);
          setloading(false);
          setconfirmationText('Waiting For Block Confirmation');
          return false;
        } else {
          activeTx = null;
          if (e.message.includes("insufficient funds")) {
            toast.error(<span><Translate>Insufficient funds to create a game</Translate></span>, {
              duration: 4000,
              style: {
                  maxWidth: 800,
                  fontSize: "14px",
                  borderRadius: "15px",
                },
              }); 
            }
          setconfirmationShow(true);
          setloading(false);
          return false;
        }
      }
    };

  const handleSubmit = async(e) => {
    let link;
    try {
      if (e && e.preventDefault) {
        e.preventDefault();
      }
    const isValid = validate();
    if (isValid) {
          setloading(true);
        let gameId = v4();
        gameId = gameId.split("-").join("");
        setconfirmationText('Please Confirm Transaction');
        
      const res = await axios.post(UrlConfig.tableCreateAPI, { 
          game: gameType.value,
          gameName: "default",
          tid: gameId,
          betMin: buyIn,
          ballance: parseFloat(balance),
          coinName: 'Matic',
          buyIn: parseFloat(buyIn),
          smallBlind: parseFloat(buyIn),
          rTimeout: parseInt(timeoutSelected.value),
          public: public_game,
          allowWat: false,
          adminStart: allow_round,
          invPlayers: playerSelected.map(el => el.value),
          media: media,
          gameTime: gameTime.value || 0,
          MaticUSD: parseFloat(balanceInUSD),
          wid:account, 
    }, {
      headers:{
        device: deviceId || device,
        },
      withCredentials: true,
      credentials: 'include',
      });
      if(res.data.error === "no error"){
       link = res.data.link;
        const tx = await createGame(gameId, link);
        if(!tx){
          setloading(false);
          console.log("not able to create game");
          await axios.get(UrlConfig.resetSessionAPI, {
            headers: {
              uid: user.uid
              },
              withCredentials: true,
              credentials: 'include',
          })
          return;
        }
        window.location.href = res.data.link;
      return;
        } else if (res.data.error === "missing auth token") {
          await cookieSetter(account, handleSubmit, ()=>{});
        } if (res.data.error === "cookie expired") {
          window.location.href = '/';
        } else if (res.data.error === "User inGame") {
        link = res.data.link;
          window.location.href = res.data.link;
          return;
        } else {
          toast.error(res.data.error, {
            style: {
              maxWidth: 800,
              fontSize: "14px",
              borderRadius: "15px",
            },
          });
    }
  }
}catch(e){
    console.log("not able to create game",e);
    if(link){
      await axios.get(UrlConfig.resetSessionAPI, {
          headers: {
          uid: user.uid
          },
          withCredentials: true,
          credentials: 'include',
      })
    }
    setloading(false);
    
    }
  };

  function validate() {
    let error = "";
    if (balance === "0.00000") {
        error = "Not enough MATIC tokens";
        console.log("MATIC tokens on Polygon network is not enough");
    }
    if(!public_game && playerSelected.length === 0){
      error = "Please invite Player for private table";
    }
    if (error) {
        setmessage(error);
      return false;
    } else {
      return true;
    }
  };

  const handleChange = (event) => {
    setgameType(event);
    if (event.value === 'LudoClassic_Tables') {
        setpublic_game(true);
    } if (event.value === 'LudoTimer_Tables') {
        setpublic_game(false);
    } if (event.value === 'LudoMoveableTimer_Tables') {
        setpublic_game(false);
    }
  };

  function hendleplayerSelect(event) {
    setplayerSelected(event);
  }

  function hendleTimeSelect(event) {
    // settimeoutSelected(event)
    setGameTime(event);
  }

  function hendleRoundTimeSelect(event) {
    settimeoutSelected(event);
  }

  function showSchedule() {
    setpickerSchedule(true);
    setselectDate(new Date());
  }

  function hideSchedule() {
    setpickerSchedule(false);
    setselectDate("no");
  }

  function onChangeDate(data) {
    console.log(data);
    setselectDate(data);
  }

  function toggleAudio(e) {
    if (e.target.checked) {
        
        setmedia("audio");
        setvideo_hide(true);
        toast.success (
            <div className="wallet-image">
              <Translate>Live voice interaction</Translate>
            </div>,
            {
              id: "toast-info",
              duration: 4000,
              style: {
                maxWidth: 800,
                fontSize: "14px",
                borderRadius: "15px",
                },
            }
          );
    } else {
        setmedia("no-media");
        setvideo_hide(false);
      } 
  };

  function toggleVideo(e) {
    if (e.target.checked) {
       
        setmedia("video");
        setaudio_hide(true);
        toast.success (
            <div className="wallet-image">
              <Translate>Live video chat</Translate>
            </div>,
            {
              id: "toast-info",
              duration: 4000,
              style: {
                maxWidth: 800,
                fontSize: "14px",
                borderRadius: "15px",
                },
            }
          );
    } else {
        setmedia("no-media");
        setaudio_hide(false);
      }
  };

  function togglePublic(e) {
    setpublic_game(e.target.checked);
    if (e.target.checked == true) {
      toast.success (
        <span className="wallet-image">
          <Translate>Anyone can join the game</Translate>
        </span>,
        {
          style: {
            maxWidth: 800,
            fontSize: "14px",
            borderRadius: "15px",
          },
        }
      );
    }
  };

  function toggleRound (e) {
    setallow_round(e.target.checked);
    if (e.target.checked == true) {
      toast.success (
        <span className="wallet-image">
          <Translate>Round start automatically</Translate>
        </span>,
        {
          style: {
            maxWidth: 800,
            fontSize: "14px",
            borderRadius: "15px",
          },
        }
      );
    }
  };

  function updatesWalletCoins(value) {
    setwallet_min(parseFloat(value));
  }

  const confirmationClose = () => {
    setconfirmationShow(!confirmationShow);
  };

  useEffect(() => {
    const getMaticUsdPrice = async(matic) => {
      const res = await axios.get(UrlConfig.maticUsdPriceUrl);
      if(res.data){
        const curentPrice = res.data.market_data.current_price.usd
        setBalanceInUSD(curentPrice * matic);
      }
    }
    const getBalance = async() => {
    if(account){
      const value = await provider?.getBalance(account);
        if(value)
        setBalance(Number(ethers.utils.formatEther(value)).toFixed(5));
        getMaticUsdPrice(Number(ethers.utils.formatEther(value)));
        setwalletConnected(true);
        setmessage("");
    } else {
        setwalletConnected(false);
        setmessage("Crypto wallet is not connected");
    }
  }
  getBalance()
}, [account, provider])



useEffect(() => {
  if(account && provider){
    const signer = provider.getSigner();
    let cont = new ethers.Contract(process.env.REACT_APP_LUDO_CONTRACT_ADDRESS, LudoAbi, signer);
    setContract(cont);
  }
},[account, provider])

useEffect(() => {
  const handleUnload = async() => {
   if(activeTx){
    await axios.get(UrlConfig.resetSessionAPI, {
      headers:{
        uid: user.uid
      },
      withCredentials: true,
      credentials: 'include',
    })
   }
  }
  window.addEventListener("beforeunload", handleUnload)
  return () => {
    window.removeEventListener("beforeunload", handleUnload)
    handleUnload();
  }
},[activeTx])

  const customStyles = {
    option: (provided) => ({
      ...provided,
      borderBottom: "1px solid #2d2d32",
      color: "#ddd",
      backgroundColor: "#191b25",
    }),
    control: () => ({
      border: "1px solid #51525f",
      borderRadius: ".25rem",
      display: "flex",
      padding: "2px 10px",
      backgroundColor: "transparent",
      color: "#fff",
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: data.color,
      ":hover": {
        backgroundColor: "#8461dc",
        color: "#fff",
      },
    }),
  };


return (
	<>
    {!walletConnected ? (
		<CryptoNOT />
	) : (
<Layout>
<div className="bet-weje">
    <div className="container">
        <div className="row">
            <div className="col-md-6 m-auto">
                <div className="bet-weje-content">
                    <div className="tooltip-text">
                        <OverlayTrigger
                          placement="right"
                          overlay={
                            <Tooltip
                              id={`tooltip-bottom-ludo`}
                              className="ludo-tooltip" >
                              <div className="ludo-table-description">
                                <h6>{gameType.label}</h6>
                                <p>{gameType.desc}</p>
                              </div>
                            </Tooltip>
                          }>
                          <h2>
                          <Translate>Ludo</Translate>
                            <small>
                              {gameType.label} <img alt="info" src={info} className='info-logo' style={{ width: '9%' }}/>
                            </small>
                          </h2>
                        </OverlayTrigger>
                      </div>
                          <img
                          src={PolyLogo}
                          alt="BlackJack"
                          className="pol-coin"
                        />

                        <Form onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-md-5">
                            <Form.Label><Translate>Select a Game</Translate></Form.Label>
                              <Select
                                name="game"
                                options={gametypes}
                                styles={customStyles}
                                onChange={handleChange}
                                defaultValue={{
                                  value: "LudoMoveableTimer",
                                  label: "Moveable Timer",
                                }}
                              />
                              </div>

                            <div className="col-md-5">
                                <Form.Group controlId="formBasicFirst">
                                    <Form.Label><Translate>Round Timeout</Translate></Form.Label>
                                    <Select
                                        name="player"
                                        options={timeoutArray}
                                        styles={customStyles}
                                        onChange={hendleRoundTimeSelect}
                                        defaultValue={{ value: "15", label: "15 second" }}
                                    />
                                </Form.Group>
                            </div>

                            <div className="col-md-5">
                                <Form.Group controlId="formBasicFirst">
                                <Form.Label><Translate>Select players</Translate></Form.Label>
                                    <Select
                                      id="SelectPlayer"
                                      name="player"
                                      options={playersArray}
                                      styles={customStyles}
                                      closeMenuOnSelect={false}
                                      isMulti
                                      onChange={hendleplayerSelect}
                                    />
                                </Form.Group>
                            </div>

                              <>
                                  {gameType.value !== "LudoClassic_Tables" ? (
                                <div className="col-sm-5">
                                  <Form.Group
                                    controlId="formBasicFirst"
                                    className="coin-input" >
                                    <Form.Label><Translate>Game Time</Translate></Form.Label>
                                    <Select
                                      name="gameTime"
                                      options={[
                                        { value: 5, label: "5 minutes" },
                                        { value: 10, label: "10 minutes" },
                                        { value: 15, label: "15 minutes" },
                                      ]}
                                      styles={customStyles}
                                      onChange={hendleTimeSelect}
                                      defaultValue={gameTime}
                                    />
                                  </Form.Group>
                                </div>
                              ) : (
                                ""
                              )}
                            </>

                            <div className="row col-md-12">
                                <div className="col-sm-5">
                                    <Form.Group
                                    controlId="formBasicFirst"
                                    className="coin-input">
                                    <Form.Label>
                                        <b>POL<span style={{ fontSize: "10px" }}>(MATIC)</span></b>
                                    </Form.Label>
                                    <div className="tooltipHover">
                                        <img src={walletIcon} alt="info" className="iconInfo" />
                                        <small className="tooltiptext matic-text">
                                          POL<span style={{ fontSize: "10px" }}>(MATIC)</span> <Translate>Balance</Translate>
                                        </small>
                                    </div>
                                    <div className="your-wallet">
                                        <img src={walletIcon} alt="info" className="PolygonCoins" />
                                        <p className="your-wallet-p">
                                            {balance}
                                        </p>
                                    </div>
                                    </Form.Group>
                                </div>
                                
                               
                            </div>

                            <div className="col-md-6">
                                <MinimumBet 
                                    value={wallet_min}
                                    setValue={setwallet_min}
                                    buyIn={buyIn}
                                    setbuyIn={setbuyIn}
                                />
                            </div>
                            
                            {pickerSchedule ? (
                                <>
                                <div className="col-md-6">
                                    <DatePicker
                                    selected={selectDate}
                                    onChange={onChangeDate}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Form.Control
                                    type="time"
                                    placeholder="Exact time"
                                    name="time"
                                    autoComplete="off"
                                    required
                                    onChange={(e) => {
                                        console.log("sss =>", e.target.value);
                                        setselectedTime(e.target.value);
                                    }}
                                    />
                                </div>
                                </>
                            ) : null}

                            <div className="col-md-6">
                                <div className="invite-radio">
                                {!audio_hide ? (
                                    <div className="filter-input checkbox">
                                        <input
                                          type="checkbox"
                                          id="video"
                                          name="media"
                                          onChange={toggleAudio} />
                                        <label className="radio-label-audio">
                                          <span className='audio-text'><Translate>Audio</Translate></span>
                                          </label>
                                        <i
                                        style={{ color: "#8461dc" }}
                                        className="fa fa-headphones came"
                                        aria-hidden="true"
                                        ></i>
                                    </div>
                                    ) : null}

                                    {!video_hide ? (
                                    <div className="filter-input checkbox">
                                        <input
                                          type="checkbox"
                                          id="video"
                                          name="media"
                                          onChange={toggleVideo} />
                                        <label className="radio-label-video">
                                          <span className='video-text'><Translate>Video</Translate></span>
                                        </label>
                                        <i
                                        style={{ color: "#8461dc" }}
                                        className="fa fa-video-camera came"
                                        aria-hidden="true"
                                        ></i>
                                    </div>
                                    ) : null}

                                    <>
                                    <div className="filter-input checkbox">
                                        <input
                                        type="checkbox"
                                        id="Public"
                                        name="public"
                                        defaultChecked={public_game}
                                        onChange={togglePublic} />
                                        <label className="radio-label-public">
                                          <span className='public-text'><Translate>Public game</Translate></span>
                                        </label>
                                    </div>
                                    </>

                                    <>
                                    <div className="filter-input checkbox">
                                        <input
                                            type="checkbox"
                                            id="round"
                                            name="watcher"
                                            defaultChecked={allow_round}
                                            onChange={toggleRound}
                                        />
                                        <label className="radio-label-automatic">
                                            <span className='automatic-text'><Translate>Auto round</Translate></span>
                                        </label>
                                    </div>
                                    </>
                  
                                    <div className="col-md-12">
                                      
                                        {loading ? (
                                            <>
                                                <Modal
                                                    show={loading}
                                                    onHide={() => confirmationClose()}
                                                    centered
                                                    className="friends-popup pending-popup">
                                                    <Modal.Header >
                                                      <Modal.Title>
                                                      <Spinner animation="border" />
                                                        <small className='title-pending'>
                                                           {confirmationText}
                                                        </small>
                                                      </Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                        <div className="feed-body">
                                                        { confirmationText === "Please Confirm Transaction" ? (
                                                          <Lottie options={confirmLottie} width={250} height={250} />
                                                        ) : <Lottie options={blockLottie} width={300} height={250} /> }
                                                        </div>
                                                    </Modal.Body>
                                                </Modal>
                                            </>
                                        ) : null}

                                        <div className="errorBoxMessage">
                                            <p className="Error"><Translate>{message}</Translate></p>
                                            <a
                                                style={{ color: "#8461dc" }}
                                                href=''>
                                                
                                            </a>
                                        </div>

                                        <div className="login-button">
                                        <Link to={"/profile"}>
                                            <Button className="btn-dark"><Translate>Cancel</Translate></Button>
                                        </Link>
                                        <Button
                                            type="submit"
                                            className="l-btn btn-gold btn-play"
                                            disabled={loading} >
                                            {loading ? (
                                            <Spinner animation="border" />
                                            ) : (
                                              <Translate>Play</Translate>
                                            )}
                                        </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                          </div>
                      </Form>
			              </div>
		              </div>
	            </div>
          </div>
      </div>
</Layout>
)}
</>
    );
};

export default LudoCreateTable;