const config = {
    //APIs
    tableCreateAPI: `https://backend.weje.com/CreateTable/${process.env.REACT_APP_ENV_MODE}`,
    setInGameAPI: `https://backend.weje.com/SetInGame/${process.env.REACT_APP_ENV_MODE}`,
    walletConnectAPI: `https://backend.weje.com/ConnectWallet/${process.env.REACT_APP_ENV_MODE}`,
    cookieSetterAPI: `https://backend.weje.com/CookieSetter/${process.env.REACT_APP_ENV_MODE}`,
    getDocAPI: `https://backend.weje.com/GetDoc/${process.env.REACT_APP_ENV_MODE}`,
    resetSessionAPI: `https://backend.weje.com/CreateTable/onCreateGameReject/${process.env.REACT_APP_ENV_MODE}`,
    transactionHistory: `https://backend.weje.com/TransHist/${process.env.REACT_APP_ENV_MODE}`,
    changeNick: `https://backend.weje.com/ChangeNick/${process.env.REACT_APP_ENV_MODE}`,
    uploadImageAPI: `https://backend.weje.com/UploadImage/${process.env.REACT_APP_ENV_MODE}`,
    addEmail: `https://backend.weje.com/AddEmail/${process.env.REACT_APP_ENV_MODE}`,

    // Outside URLs Call
    maticUsdPriceUrl: "https://api.coingecko.com/api/v3/coins/matic-network",
    geoLocationUrl: "https://www.cloudflare.com/cdn-cgi/trace",
    polygonDataUrl: "https://api.coincap.io/v2/assets/polygon/",

    //Game servers
    pokerServer: `https://backend.weje.com/PokerServer`,
    backgammonServer: "https://backgammon-server01-t3e66zpola-uc.a.run.app",
    blackjackServer: "https://blackjack-server-t3e66zpola-uc.a.run.app",
    ludoServer: "https://ludo-server-t3e66zpola-uc.a.run.app",

    //Push noti urls
    pushTokenAPI: "https://push-token-init-t3e66zpola-uc.a.run.app",

    // Friends Module
    searchFriends: `https://backend.weje.com/SearchUsers/${process.env.REACT_APP_ENV_MODE}`,
    acceptFriendsRequest: `https://backend.weje.com/AcceptFriend/${process.env.REACT_APP_ENV_MODE}`,
    rejectFriendRequest: `https://backend.weje.com//RejectFriendRequest/${process.env.REACT_APP_ENV_MODE}`,
    sendFriendRequest: `https://backend.weje.com/FriendRequest/${process.env.REACT_APP_ENV_MODE}`,
    UnfriendApi: `https://backend.weje.com/UnfriendApi/${process.env.REACT_APP_ENV_MODE}`,
    showFriends: `https://backend.weje.com/SocialLists/${process.env.REACT_APP_ENV_MODE}`,
    peopleYouKnow: `https://backend.weje.com/PeopleKnow/${process.env.REACT_APP_ENV_MODE}`,

    // Polygon chain Hash
    polygonChain: "https://polygonscan.com/tx",
    gasTrackerUrl: "https://api.polygonscan.com/api?module=gastracker&action=gasoracle&apikey=",

    //Sports bet
    openMatchSearch: `https://backend.weje.com/SportRpsLimiter/${process.env.REACT_APP_ENV_MODE}`,
    createTicketValidation: `https://backend.weje.com/SportsBet/createTicketValidate/${process.env.REACT_APP_ENV_MODE}`,
    setFcmToken: `https://backend.weje.com/GetDoc/${process.env.REACT_APP_ENV_MODE}/FcmProxy/`
}

export default config;